.container {
    font-family: 'Roboto', sans-serif;
    padding: 15px;
    background-color: #f4f4f4;
    
    display: flex;
    flex-direction: column;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    background-color: #333;
    padding: 12px 20px;
    border-radius: 5px;
    align-items: center;
    color: #fff;
    margin-bottom: 20px;
}

.mainContent {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 15px;
    flex-grow: 1;
    /* Ensures the content area expands */
}

.employeeList {
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    overflow-y: hidden;
    width: 70%;
    /* Reducing width */
}

.employeeDetails,
.timesheetDetails {
    overflow: hidden;

    border-radius: 5px;

}


.actionButtonsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    /* Optional: Add space above the buttons if required */
}


.individualTimesheet {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    transition: background-color 0.3s;
}

.individualTimesheet:hover {
    background-color: #eee;
}

.settingsButton,
.closeButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #fff;
    transition: color 0.3s;
}

.settingsButton:hover,
.closeButton:hover {
    color: #ddd;
}

.settingsModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.employeeList h3 {
    cursor: pointer;
    transition: color 0.3s;
}

.employeeList h3:hover {
    color: #555;
}

/* Styling for Material UI Buttons */
.MuiButton-outlined {
    margin: 5px;
}

.timesheetContainer {
    max-height: 480px;
    /* adjust this value as needed */
    overflow-y: auto;
    overflow-x: hidden;
}

.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: #fff;
    padding: 20px;
    border: 2px solid #000;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
    outline: none;
}