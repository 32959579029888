.rbc-button-link {
    background-color: #CE93D8; /* Your desired background color */
    color: #333; /* Adjust text color if needed */
    border-radius: 4px; /* Optional: if you want rounded corners */
    padding: 5px 10px; /* Optional: for better spacing */
}
.event-done {
    text-decoration: line-through;
    opacity: 0.6;
  }
  
  .dialogContentTransparent {
    background-color: transparent;
  }
  
  .lottie-container {
    background-color: transparent;
  }